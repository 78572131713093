


























































import {Component, Ref, Vue} from "vue-property-decorator"
import Blog from "@/models/Blog";
import BlogService from "@/services/BlogService";
import {format} from "date-fns";
import {es} from "date-fns/locale";
import Marked from 'marked'

@Component
export default class BlogView extends Vue {
    loading: boolean = false
    blog: Blog = new Blog()
    @Ref() readonly markedId!: HTMLParagraphElement


    created() {
        BlogService.getBlog(this, parseInt(this.$route.params.id))
            .then(() => {
            this.markedId.innerHTML = Marked(this.blog.body!)
        })

    }

    mounted() {
        this.markedId.innerHTML = Marked(this.blog.body!) + "prueba"
    }

    date(createdAt: string) {
        return format(new Date(createdAt), "dd $ MMMM, YYY", {locale: es})
    }

    goingTo(dir : string) {
        window.open(dir, "_blank")
    }

}
